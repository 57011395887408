const MONETIZATION_PAGES_PATHES = [
  '/upgrade',
  '/upgrade_trial',
  '/credit-upgrade',
  '/payment',
  '/payments_credit',
  '/payments_vip',
  '/promote',
  '/feature',
  '/buy-sticker-packs',
  '/pay.google/vertification',
];

export const isNonPopunderLanding = (pathname: string) =>
  /^\/landing\/lp(55|56)\/?(1)?$/.test(pathname);

export const isPrelandPage = (pathname: string) => {
  return pathname.includes('/landing/lp');
};

export const isMainPage = (pathname: string) => pathname === '/';

export const isLoginPage = (pathname: string) => pathname.includes('login');

export const prelandIdFormPathname = (pathname: string) => {
  let prelandId = pathname?.split?.('landing/lp')?.[1]?.match?.(/\d+/)?.[0];

  if (!prelandId) {
    prelandId = '99';
  }

  if (pathname.includes('/entry/click')) {
    prelandId = '98';
  }

  if (isMainPage(pathname)) {
    prelandId = '0';
  }

  if (isLoginPage(pathname)) {
    prelandId = '96';
  }
  return Number(prelandId);
};

export const isTermsOrPolicyPage = (pathname: string) =>
  pathname.match(/(\/terms)|(\/(.*)?policy)/gi);

export const isMonetizationPage = (pathname: string) => {
  const reg = new RegExp(MONETIZATION_PAGES_PATHES.join('|'), 'gi');
  return pathname.match(reg);
};

export const isUserVideoPage = (pathname: string) => {
  return pathname.match(/(\/user[0-9]+\/videos\/[0-9]+)/gi);
};

export const LANDING_PATHNAME_REGEX = /\/landing\/lp\d{1,2}/;

interface BuildNewUrl {
  pathname: string;
  search: string;
  step: any;
}

export const buildNewLandingUrl = ({ pathname, search, step }: BuildNewUrl) => {
  const newSearch = search
    ? search.includes('?')
      ? search
      : `?${search}`
    : '';
  const url = pathname.match(LANDING_PATHNAME_REGEX);
  return `${url}/${step}${newSearch}`;
};

export const getLandingIdFromUrl = (pathname: string) => {
  if (pathname.match(LANDING_PATHNAME_REGEX)) {
    const splitedUrl = pathname.split('/');
    return parseInt(splitedUrl[2].replace('lp', ''), 10);
  }
  return null;
};

export const getStepFromUrl = (pathname: string) => {
  const splitUrl = pathname.split('/');
  return parseInt(splitUrl[splitUrl.length - 1], 10);
};

export const isNotificationsPage = (pathname: string) =>
  pathname.includes('/activities/notifications');

export const isPromoteVipPage = (pathname: string) =>
  pathname.includes('/promote');
